import {inject, injectable} from "inversify";
import type {IHttpClientService} from "data/services/http";
import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";
import type {
	IArticles,
	ICategories,
	ISections,
} from "data/stores/static_content/static_content.store";
import type {IPlayer} from "data/stores/players/players.store";
import type {ICountry} from "data/stores/countries/countries.store";
import type {IRound} from "data/stores/rounds/rounds.store";
import type {ISquad} from "data/stores/squads/squads.store";
import type {IChecksums} from "data/stores/checksum/checksum.store";
import {Language} from "data/enums";
import type {IContest, IContestList, IContests} from "data/types/contests";
import {ICompetitions} from "data/types/competitions";
// import {STATIC_CONTENT_JSON_URL} from "data/constants";
import {ILeaderboard} from "data/types/leaderboard";
import {INotificationBar} from "data/stores/notification/notification.store";

export interface IJSONProvider {
	countries(): Promise<AxiosResponse<ICountry[]>>;

	leaderboard(): Promise<AxiosResponse<ILeaderboard>>;

	rounds(): Promise<AxiosResponse<IRound[]>>;

	players(): Promise<AxiosResponse<IPlayer[]>>;

	squads(): Promise<AxiosResponse<ISquad[]>>;

	checksums(): Promise<AxiosResponse<IChecksums>>;

	//contests(): Promise<AxiosResponse<IContests>>;

	notificationBar(game: string): Promise<AxiosResponse<INotificationBar>>;

	competitions(): Promise<AxiosResponse<ICompetitions>>;

	translations(locale: string): Promise<AxiosResponse<Record<string, unknown>>>;

	helpCategories(locale: string): Promise<AxiosResponse<ICategories>>;

	helpSections(locale: string): Promise<AxiosResponse<ISections>>;

	helpArticles(locale: string): Promise<AxiosResponse<IArticles>>;

	contestLists(): Promise<AxiosResponse<IContestList[]>>;
	thisWeekContestLists(): Promise<AxiosResponse<IContestList[]>>;
	lastWeekContestLists(): Promise<AxiosResponse<IContestList[]>>;

	completedList(sport: string): Promise<AxiosResponse<IContest[]>>;
	activeList(sport: string): Promise<AxiosResponse<IContest[]>>;

	allCompletedList(): Promise<AxiosResponse<IContest[]>>;
	allActiveList(): Promise<AxiosResponse<IContest[]>>;
}

@injectable()
export class JSONProvider implements IJSONProvider {
	constructor(
		@inject(Bindings.JsonHTTPClient) private _jsonClient: IHttpClientService,
		@inject(Bindings.ContentJsonHTTPClient) private _contentJsonClient: IHttpClientService
	) {}

	countries = () => this._jsonClient.get<ICountry[]>("countries.json");
	leaderboard = () => this._jsonClient.get<ILeaderboard>("predictor/overall_leaderboard.json");
	rounds = () => this._jsonClient.get<IRound[]>("rounds.json");
	players = () => this._jsonClient.get<IPlayer[]>("players.json");
	squads = () => this._jsonClient.get<ISquad[]>("squads.json");
	checksums = () => this._jsonClient.get<IChecksums>("/predictor/checksums.json");
	competitions = () => this._jsonClient.get<ICompetitions>("competitions.json");
	contests = () => this._jsonClient.get<IContests>("/predictor/contests.json");
	notificationBar = (game: string) =>
		this._jsonClient.get<INotificationBar>(`/notification_bar/${game}.json`);
	translations = (locale: string) =>
		this._jsonClient.get<Record<string, unknown>>(`locale/${locale}.json`);

	helpCategories = (locale: Language) =>
		this._contentJsonClient.get<ICategories>(`/categories.json`);
	helpSections = (locale: Language) => this._contentJsonClient.get<ISections>(`/sections.json`);
	helpArticles = (locale: Language) => this._contentJsonClient.get<IArticles>(`/articles.json`);
	contestLists = () => this._jsonClient.get<IContestList[]>("/predictor/contests-list.json");
	thisWeekContestLists = () =>
		this._jsonClient.get<IContestList[]>("/predictor/contests-current-week.json");
	lastWeekContestLists = () =>
		this._jsonClient.get<IContestList[]>("/predictor/contests-previous-week.json");

	completedList = (sport: string) =>
		this._jsonClient.get<IContest[]>(`/predictor/contests-completed-${sport}.json`);
	activeList = (sport: string) =>
		this._jsonClient.get<IContest[]>(`/predictor/contests-${sport}.json`);

	allCompletedList = () =>
		this._jsonClient.get<IContest[]>(`/predictor/contests-completed}.json`);
	allActiveList = () => this._jsonClient.get<IContest[]>(`/predictor/contests.json`);
}
