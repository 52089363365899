import {makeAutoObservable, observable, runInAction} from "mobx";
import {injectable, inject} from "inversify";
import type {IContest, IContestList, IQuestion} from "data/types/contests";
import {Bindings} from "data/constants/bindings";
import type {IJSONProvider} from "data/providers/json/json.provider";
import {findIndex, findLast, get, map} from "lodash";
import {ContestStatusEnum, QuestionStatusEnum, RequestState, SportParam} from "data/enums";
import {QuestionUtils} from "data/utils/question";

export type IITerribleType = IContest;

export type IListContestType = IContestList;

type QuestionStatusType = {
	[key: string]: QuestionStatusEnum;
};

export interface IContestStore {
	get list(): IITerribleType[];
	get liveContestIDs(): number[];
	get openQuestionIDs(): number[];

	get lastCompleted(): IITerribleType | undefined;
	get completedOrLiveContests(): IITerribleType[];

	get contestList(): IListContestType[];
	get thisWeekContestList(): IListContestType[];
	get lastWeekContestList(): IListContestType[];

	get questionStatusObj(): QuestionStatusType;

	get isThisWeek(): boolean;
	set isThisWeek(isThisWeek);

	getCompletedOrLiveContestsByGame(competitionName: string): IITerribleType[];

	getByID(id: number): IITerribleType | undefined;

	getByIDFromList(id: number): IContestList | undefined;

	safeFetch(): Promise<IListContestType[]>;

	updateFetch: () => void;

	fetchList(): Promise<IListContestType[]>;
	// fetchThisWeekList(): Promise<IListContestType[]>;
	// fetchLastWeekList(): Promise<IListContestType[]>;

	getCompletedList(sport: string): Promise<IITerribleType[]>;

	getActiveList(sport: SportParam): Promise<IITerribleType[]>;

	getAllCompletedList(): Promise<IITerribleType[]>;

	getAllActiveList(): Promise<IITerribleType[]>;

	changeIsThisWeek: (isThisWeek: boolean) => void;
}

@injectable()
export class ContestStore implements IContestStore {
	constructor(@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider) {
		makeAutoObservable(this);
	}

	@observable private _list: IITerribleType[] = [];
	@observable private _openQuestionIds: number[] = [];
	@observable private _liveContestIds: number[] = [];
	@observable private _questionStatusObj: QuestionStatusType = {};
	@observable private _contestLists: IListContestType[] = [];
	@observable private _thisWeekContestList: IListContestType[] = [];
	@observable private _lastWeekContestList: IListContestType[] = [];
	@observable private _isThisWeek: boolean = true;
	@observable private _initCalled: boolean = false;
	@observable private _requestState = RequestState.IDLE;

	private getTempArr(liveContests: IContest[]) {
		return liveContests.map((item) =>
			item.questions
				.filter((item) => item.status === QuestionStatusEnum.OPEN)
				.map((item) => item.id)
		);
	}

	get isThisWeek(): boolean {
		return this._isThisWeek;
	}

	set isThisWeek(isThisWeek) {
		this._isThisWeek = isThisWeek;
	}

	get list() {
		return this._list;
	}

	set list(list: IITerribleType[]) {
		this._list = list;
	}

	get liveContestIDs() {
		return this._liveContestIds;
	}

	get openQuestionIDs() {
		return this._openQuestionIds;
	}

	get lastCompleted(): IITerribleType | undefined {
		return findLast(
			this._list,
			(it: IITerribleType) => it.status === ContestStatusEnum.COMPLETE
		);
	}

	get completedOrLiveContests() {
		return this.list.filter((contest) => {
			if (contest.status === ContestStatusEnum.COMPLETE) {
				return true;
			}

			if (contest.status === ContestStatusEnum.LIVE) {
				return QuestionUtils.IS_FIRST_COMPLETE(contest);
			}

			return false;
		});
	}

	get contestList() {
		return this._contestLists;
	}

	get thisWeekContestList() {
		return this._thisWeekContestList;
	}

	get lastWeekContestList() {
		return this._lastWeekContestList;
	}

	get questionStatusObj(): QuestionStatusType {
		return this._questionStatusObj;
	}

	changeIsThisWeek = (isThisWeek: boolean): void => {
		this.isThisWeek = isThisWeek;
	};

	getCompletedOrLiveContestsByGame(competitionName: string) {
		return this.completedOrLiveContests.filter(
			(contest) => contest.competition === competitionName.toUpperCase()
		);
	}

	getByID(id: number): IITerribleType | undefined {
		if (!id) {
			return;
		}
		return this._list.find((it) => {
			return it.id === id;
		});
	}

	getByIDFromList(id: number): IContestList | undefined {
		if (!id) {
			return;
		}
		return this._contestLists.find((it) => {
			return it.id === id;
		});
	}

	async fetchList(): Promise<IListContestType[]> {
		this._requestState = RequestState.PENDING;
		const {data: thisWeekData} = await this._jsonProvider.thisWeekContestLists();
		if (thisWeekData) {
			runInAction(() => {
				this._thisWeekContestList = thisWeekData;
			});
		}

		const {data: lastWeekData} = await this._jsonProvider.lastWeekContestLists();
		if (lastWeekData) {
			runInAction(() => {
				this._lastWeekContestList = lastWeekData;
			});
		}

		const data = this._thisWeekContestList.concat(this._lastWeekContestList);
		if (data) {
			runInAction(() => {
				this._requestState = RequestState.SUCCESS;
				this._contestLists = data;
				const liveContests = data.filter((item) => item.status === ContestStatusEnum.LIVE);
				this._liveContestIds = liveContests.map((item) => item.id);
				this._questionStatusObj = data.reduce((acc: QuestionStatusType, curr) => {
					const questions = get(curr, "questions", []);
					questions.forEach((question: IQuestion) => {
						acc[question.id] = question.status;
					});
					return acc;
				}, {});
			});
		}

		return this._contestLists;
	}

	// async fetchThisWeekList(): Promise<IListContestType[]> {
	// 	const {data} = await this._jsonProvider.thisWeekContestLists();
	// 	if (data) {
	// 		runInAction(() => {
	// 			this._thisWeekContestList = data;
	// 		});
	// 	}

	// 	return data;
	// }

	// async fetchLastWeekList(): Promise<IListContestType[]> {
	// 	const {data} = await this._jsonProvider.lastWeekContestLists();
	// 	if (data) {
	// 		runInAction(() => {
	// 			this._lastWeekContestList = data;
	// 		});
	// 	}

	// 	return data;
	// }

	async getAllCompletedList(): Promise<IITerribleType[]> {
		const response = await this._jsonProvider.allCompletedList();
		if (response.data) {
			runInAction(() => {
				map(response.data, (row) => {
					const idx = findIndex(this._list, function (record) {
						return record.id === row.id;
					});
					if (idx > -1) {
						this._list[idx] = row;
					} else {
						this._list.push(row);
					}
				});
			});
		}
		return response.data;
	}

	async getAllActiveList(): Promise<IITerribleType[]> {
		const response = await this._jsonProvider.allActiveList();
		if (response.data) {
			runInAction(() => {
				map(response.data, (row) => {
					const idx = findIndex(this._list, function (record) {
						return record.id === row.id;
					});
					if (idx > -1) {
						this._list[idx] = row;
					} else {
						this._list.push(row);
					}
				});
				const liveContests = response.data.filter(
					(item) => item.status === ContestStatusEnum.LIVE
				);
				if (liveContests.length) {
					this._liveContestIds = liveContests.map((item) => item.id);
					const tempArr = this.getTempArr(liveContests);

					this._openQuestionIds = tempArr.flat();
				}
			});
		}

		return response.data;
	}

	async getCompletedList(sport: string): Promise<IITerribleType[]> {
		const response = await this._jsonProvider.completedList(sport);
		if (response.data) {
			runInAction(() => {
				map(response.data, (row) => {
					const idx = findIndex(this._list, function (record) {
						return record.id === row.id;
					});
					if (idx > -1) {
						this._list[idx] = row;
					} else {
						this._list.push(row);
					}
				});
			});
		}
		return response.data;
	}

	async getActiveList(sport: SportParam): Promise<IITerribleType[]> {
		const response = await this._jsonProvider.activeList(sport);
		if (response.data) {
			runInAction(() => {
				map(response.data, (row) => {
					const idx = findIndex(this._list, function (record) {
						return record.id === row.id;
					});
					if (idx > -1) {
						this._list[idx] = row;
					} else {
						this._list.push(row);
					}
				});
				const liveContests = Array.isArray(response.data)
					? response.data.filter((item) => item?.status === ContestStatusEnum.LIVE)
					: [];

				if (liveContests.length) {
					this._liveContestIds = liveContests.map((item) => item.id);
					const tempArr = this.getTempArr(liveContests);

					this._openQuestionIds = tempArr.flat();
				}
			});
		}

		return response.data;
	}

	updateFetch = (): void => {
		void this.fetchList();
	};

	async safeFetch(): Promise<IListContestType[]> {
		if (!this._contestLists.length && !this._initCalled) {
			this._initCalled = true;
			return this.fetchList();
		}
		return this._contestLists;
	}
}
