// import {Racing_Predictions_Extra_Temp_Data} from "data/constants";

import {AxiosError} from "axios";
import {Bindings} from "data/constants/bindings";
import {
	ModalType,
	PlacerStatus,
	QuestionStatusEnum,
	RaceOrTips,
	RacesStatusEnum,
	RequestState,
} from "data/enums";
import {IResultPosition} from "data/providers/api/answers.api.provider";
import {IApiResponse} from "data/services/http";
import type {IAnswersStore} from "data/stores/answers/answers.store";
import type {IContestStore} from "data/stores/contest/contest.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IModalsStore} from "data/stores/modals/modal.store";
import type {IUserStatsStore} from "data/stores/user_stats/user_stats.store";
import {IAnswer, IAnswerPayload, IContest, IOption, IQuestion} from "data/types/contests";
import {IRaces, ISilks} from "data/types/races";
import {ViewController} from "data/types/structure";
import {extractErrorMessage} from "data/utils";
import {inject, injectable} from "inversify";
import {
	clone,
	cloneDeep,
	countBy,
	filter,
	find,
	findIndex,
	includes,
	isEmpty,
	keyBy,
	keys,
	maxBy,
} from "lodash";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {useLocation, useNavigate} from "react-router-dom";

interface IParams {
	navigate: ReturnType<typeof useNavigate>;
	location: ReturnType<typeof useLocation>;
	contestID: number;
	competition: string | undefined;
}

interface ISaveParams {
	raceQuestionId?: number;
}

export interface IRacingPicksController extends ViewController<IParams> {
	get selectedRace(): number;
	get currentChooseRace(): IQuestion | undefined;
	get predictions(): IAnswerPayload[];
	get tabValue(): number;
	get questions(): IContest["questions"];
	get contest(): IContest | undefined;
	get userPredictionCount(): number;
	get questionsCount(): number;
	get isShowEditConfirmModal(): boolean;
	get canSavePicks(): boolean;
	get isLoading(): boolean;
	get isPageLoading(): boolean;
	get eventsName(): string[];
	get selectedEventName(): string;
	get filteredQuestions(): IContest["questions"];
	get isUpdating(): boolean;
	get isQuestionsCountEqualsPredictions(): boolean;

	setSelectedEvent: (name: string) => void;
	getIsLocked: (questionId: number | undefined) => boolean;
	getWinOrPlace: (questionId: number) => PlacerStatus;
	showSeparator: (race: IQuestion, x: number) => boolean;
	setSelectedRace: (id: number) => void;
	setSelectedRaceAndTab: (id: number, tab: number) => void;
	setHorseInSelectedRace: (
		raceId: number,
		horseId: number,
		isMobile?: boolean,
		questionId?: number
	) => void;
	removeHorseInSelectedRace: (raceId: number) => void;
	showRaceOrTips: (race_number: number) => RaceOrTips;
	isShowHorseActionIcons: (race_number: number | undefined) => boolean;
	getSelectedHorseForRound: (race_number: number) => ISilks | undefined;
	getWinner: (question: number) => IResultPosition[] | [];
	getSecondWinner: (questionId: number) => IResultPosition[] | [];
	getThirdWinner: (questionId: number) => IResultPosition[] | [];
	setTabValue: (event: React.SyntheticEvent, newValue: number) => void;
	clearUserPrediction: () => void;
	save: (params: ISaveParams) => void;
	saveInGameBar: () => void;
	confirmPicksClick: () => void;
	editPicksClick: () => void;
	getShowRaceStatus: (status: string, distance: number) => string;
	get getGamesWinner(): IOption[] | undefined;
}

@injectable()
export class RacingPicksController implements IRacingPicksController {
	@observable private _contestState: RequestState = RequestState.IDLE;
	@observable private _predictionUpdate: boolean = false;
	@observable private _requestState: RequestState = RequestState.IDLE;
	private _navigate: IParams["navigate"] | null = null;
	private _location!: IParams["location"];
	@observable private _races: IRaces[] = [];
	@observable _selectedRace: number = 1;
	private _tabValue: number = 0;
	@observable private _predictions: IAnswerPayload[] = [];
	@observable private _contestID: number = 0;
	@observable private _competition: string = "";
	@observable private _isShowEditConfirmModal: boolean = false;
	@observable private _canSavePicks: boolean = false;
	private _selectedRaceChanged: boolean = true;
	@observable private _errorMsg: string | null = null;
	@observable _selectedEvent: string = "";
	@observable private _selectedQuest: IContest["questions"] = [];
	@observable _hasUnSavePrediction: boolean = false;
	constructor(
		@inject(Bindings.ContestStore) private _contestStore: IContestStore,
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.AnswersStore) private _answersStore: IAnswersStore,
		@inject(Bindings.UserStatsStore) private _userStatsStore: IUserStatsStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get isLoading() {
		return this._contestState === RequestState.PENDING;
	}

	get isPageLoading() {
		return this._requestState === RequestState.PENDING;
	}

	get isUpdating() {
		return this._predictionUpdate === true;
	}
	get contest() {
		return this._contestStore.getByID(this._contestID);
	}

	get questions() {
		const questions = this.contest?.questions;

		if (!questions) {
			return [];
		}

		this._selectedRace = this._selectedQuest.slice().sort((a, b) => a.id - b.id)[0]?.id;

		return this.contest?.questions || [];
	}

	get filteredQuestions() {
		if (this.selectedEventName === "") {
			return this._selectedQuest.slice().sort((a, b) => a.id - b.id);
		}
		const filtered = filter(this.questions, {eventName: this._selectedEvent});
		this._selectedRace = filtered[0].id;
		this._selectedRaceChanged = true;
		return filtered.slice().sort((a, b) => a.id - b.id);
	}

	get questionsCount() {
		return this.contest?.questions.length || 0;
	}

	get isQuestionsCountEqualsPredictions() {
		return this.predictions.length === this.questionsCount;
	}

	get isShowEditConfirmModal() {
		return this._isShowEditConfirmModal;
	}

	get canSavePicks() {
		return this._canSavePicks;
	}

	get predictions() {
		return this._predictions;
	}

	get selectedRace() {
		return this._selectedRace;
	}

	get currentChooseRace() {
		return this.filteredQuestions.find((race) => race.id === this._selectedRace);
		//return this.questions.find((race) => race.id === this.selectedRace);
	}

	get tabValue() {
		return this._tabValue;
	}

	get userPredictionCount() {
		return this._predictions.length;
	}
	get eventsName() {
		return keys(
			countBy(this.questions, function (data) {
				return data.eventName;
			})
		);
	}

	get selectedEventName() {
		return this._selectedEvent;
	}

	setSelectedEvent = (name: string) => {
		this._selectedEvent = name;
	};

	getIsLocked = (questionId: number | undefined) => {
		const selected = this.questions.find((race) => race.id === questionId);
		if (selected) {
			return selected.status === QuestionStatusEnum.LOCKED;
		}
		return false;
	};

	getWinOrPlace = (questionId: number) => {
		let res = PlacerStatus.LOSER;
		if (this._answersStore.list) {
			const idx = findIndex(this._answersStore.list, function (race) {
				return race.questionId === questionId;
			});
			if (idx > -1) {
				const objectKeys: IAnswer = clone(this._answersStore.list[idx]);

				const {correctValue, value, fourthPlace, thirdPlace, secondPlace} = objectKeys;

				if (includes(correctValue, value)) {
					res = PlacerStatus.WINNER;
				} else if (
					includes(thirdPlace, value) ||
					includes(secondPlace, value) ||
					includes(fourthPlace, value)
				) {
					res = PlacerStatus.PLACER;
				} else {
					res = PlacerStatus.LOSER;
				}
			}
		}

		return res;
	};

	@action getShowRaceStatus = (status: string, distance: number) => {
		if (status === QuestionStatusEnum.COMPLETE) {
			return "COMPLETE";
		}
		if (status === QuestionStatusEnum.LOCKED) {
			return "LIVE";
		}
		if (status === QuestionStatusEnum.OPEN && this.contest?.status !== "live") {
			return distance ? `${distance}m` : "";
		}

		return "";
	};

	@action setTabValue = (event: React.SyntheticEvent, newValue: number) => {
		this._tabValue = newValue;
	};

	@action getResultCurrentChooseRace = (id?: number) => {
		if (this._selectedRaceChanged) {
			runInAction(() => {
				const currentQuestion = this.questions.find(
					(race) => race.id === id || this._selectedRace
				);
				if (currentQuestion && currentQuestion.status === QuestionStatusEnum.COMPLETE) {
					this._answersStore
						.fetchResult({
							questionId: id || this._selectedRace,
							lang: this.i18n.lang,
						})
						.catch(() => (this._requestState = RequestState.ERROR));
				}
				this._selectedRaceChanged = false;
			});
		}
	};

	showSeparator = (race: IQuestion, index: number): boolean => {
		return race.info.raceNumber === 1 && index !== 0;
	};

	@action getWinner = (raceId: number) => {
		this.getResultCurrentChooseRace(raceId);
		return this._answersStore.result?.winner || [];
	};

	@action getSecondWinner = (raceId: number) => {
		return this._answersStore.result?.second || [];
	};

	getThirdWinner = (raceId: number) => {
		return this._answersStore.result?.third || [];
	};

	get getGamesWinner() {
		if (this.currentChooseRace?.answer) {
			return (
				this.currentChooseRace.options.filter((option) =>
					this.currentChooseRace?.answer.includes(option.id)
				) || []
			);
		}
	}

	getSelectedHorseForRound = (race_number: number) => {
		const selectedHorseIdForRound = find(this._predictions, {questionId: race_number})?.value;
		const selectedQuestion = this.questions.find((question) => question.id === race_number);
		return find(selectedQuestion?.options, {id: selectedHorseIdForRound});
	};

	showRaceOrTips = (race_number: number) => {
		return !find(this._predictions, {questionId: race_number})?.value
			? RaceOrTips.RACE
			: RaceOrTips.TIPS;
	};

	isShowHorseActionIcons = (race_number: number | undefined) => {
		return find(this._races, {race_number: race_number})?.status !== RacesStatusEnum.COMPLETE;
	};

	setSelectedRace = (id: number) => {
		runInAction(() => {
			this._selectedRace = id;
			this._selectedRaceChanged = true;
		});
	};
	setSelectedRaceAndTab = (id: number, tab: number) => {
		this._selectedRace = id;
		this._tabValue = tab;
		this._selectedRaceChanged = true;
		window.scrollTo(0, 0);
	};

	private updatePredictions(
		raceId: number,
		horseId: number,
		newPredictions: Array<{questionId: number; value: number}>,
		resultByRaceID: Record<number, {race_id: number; horse_id: number}>
	) {
		const existingAnswer = newPredictions.filter((item) => item.questionId === raceId);
		this._predictionUpdate = true;
		if (existingAnswer.length) {
			newPredictions.forEach((item) => {
				const updateHorseID = resultByRaceID[item.questionId]
					? resultByRaceID[item.questionId].horse_id
					: item.value;
				item.value = updateHorseID;
			});
		} else {
			newPredictions.push({questionId: raceId, value: horseId});
		}
		this._predictions = newPredictions;
		this._answersStore.list = newPredictions;
	}

	checkRaceId(nextId: number, maxId: number, optionalId: number): number {
		if (this.questions.some((item) => item.id === nextId)) {
			return nextId;
		}
		if (nextId < maxId) {
			return this.checkRaceId(nextId + 1, maxId, optionalId);
		}
		return optionalId;
	}
	setHorseInSelectedRace = async (
		raceId: number,
		horseId: number,
		isMobile?: boolean,
		questionId?: number
	) => {
		const picks = [{race_id: raceId, horse_id: horseId}];
		const resultByRaceID = keyBy(picks, "race_id");
		const newPredictions = cloneDeep(this._predictions);

		this.updatePredictions(raceId, horseId, newPredictions, resultByRaceID);
		const maxRaceId = maxBy(this.questions, "id")?.id || raceId;
		const unAnswered = this.questions
			.filter(
				(item) =>
					!this._predictions.map((obj) => obj.questionId).includes(item.id) &&
					item.status === QuestionStatusEnum.OPEN
			)
			.map((item) => item.id);
		const optionalId = unAnswered ? unAnswered[0] : this.questions[0].id;
		//const newRaceId = raceId + 1 > maxRaceId ? optionalId : raceId + 1;
		const newRaceId = this.checkRaceId(raceId + 1, maxRaceId, optionalId);
		if (raceId + 1 > maxRaceId && unAnswered.length === 0) {
			await this.saveInGameBar();
		} else {
			isMobile ? this.setSelectedRaceAndTab(newRaceId, 1) : this.setSelectedRace(newRaceId);
			await this.save({raceQuestionId: questionId});
		}
		await this._userStatsStore.fetch(this.i18n.lang);
	};

	removeHorseInSelectedRace = async (raceId: number) => {
		const newPredictions = cloneDeep(this._predictions);
		newPredictions.forEach((item) => {
			if (item.questionId === raceId) {
				item.value = 0;
			}
		});
		this._predictions = newPredictions;
		this._answersStore.list = newPredictions;
		await this.save({});
		await this._userStatsStore.fetch(this.i18n.lang);
	};

	clearUserPrediction = () => {
		this._predictions = [];
		this._answersStore.list = [];
	};

	@action dispose(): void {
		this._contestState = RequestState.IDLE;
	}

	save = async (params: ISaveParams) => {
		this._requestState = RequestState.PENDING;
		await this._answersStore
			.save({
				raceQuestionId: params.raceQuestionId,
				contestId: this._contestID,
				lang: this.i18n.lang,
			})
			.then(() => {
				runInAction(() => {
					this._predictions = this._answersStore.list.map((item) => {
						return {
							questionId: item.questionId,
							value: item.value,
							correctValue: item.correctValue,
						};
					});
					this._predictionUpdate = false;
					this._requestState = RequestState.SUCCESS;
				});
			})
			.catch(this.onError);
	};

	saveInGameBar = async () => {
		await this.save({});
		this._isShowEditConfirmModal = true;
	};

	confirmPicksClick = () => {
		this._isShowEditConfirmModal = false;
		//await this.save({});
		this._navigate?.(`/landing`);
	};

	editPicksClick = () => {
		this._isShowEditConfirmModal = false;
	};

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;
		this._errorMsg = extractErrorMessage(error);
		this._modalsStore.showModal(ModalType.ERROR, {
			message: this._errorMsg,
			payload: "",
		});
	};

	async init(params: IParams): Promise<void> {
		if (isEmpty(this._answersStore.list)) {
			this._selectedEvent = "";
			this._selectedQuest = this.questions;
			this._contestID = params.contestID;
			if (!this.isUpdating) {
				this._predictionUpdate = true;
				this._contestState = RequestState.PENDING;
				await this._answersStore
					.fetch({
						contestId: params.contestID,
						lang: this.i18n.lang,
					})
					.then(() => {
						runInAction(() => {
							this._predictionUpdate = false;
							this._predictions = this._answersStore.list.map((item) => {
								return {
									questionId: item.questionId,
									value: item.value,
									correctValue: item.correctValue,
								};
							});
							this._contestState = RequestState.SUCCESS;
						});
					})
					.catch(() => {
						runInAction(() => {
							this._contestState = RequestState.ERROR;
						});
					});
			}
		}
		this._selectedQuest = this.questions;
		if (params.competition) {
			this._competition = params.competition;
		}
		this._navigate = params.navigate;
	}
}
